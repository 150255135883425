import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectContrato (Vue, idcontrato) {
    const apiFilter = new APIFilter()
      .addExact('idcontrato', idcontrato)
    const resp = await Vue.$api.call('contrato.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idcontrato, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilter = new APIFilter()
      .addExact('idcontrato', idcontrato)
      .addGTE('estado', 1)
    apiCalls.push({ name: 'selectContratoCuotaCount', method: 'contratoCuota.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectContratoMaterialCount', method: 'contratoMaterial.select', params: { filter: apiFilter, wrapper: 'count' } })
    const apiFilterServicioContratado = new APIFilter()
      .addExact('idcontrato', idcontrato)
      .addGTE('estado', 1)
      .addExact('contratado', 1)
    apiCalls.push({ name: 'selectServicioContratadoCount', method: 'servicioContratado.select', params: { filter: apiFilterServicioContratado, wrapper: 'count' } })
    const apiFilterDato = new APIFilter()
      .addExact('id', idcontrato)
      .addExact('idtabla', TABLA.contrato.idtabla)
      .addGTE('estado', 1)
    apiCalls.push({ name: 'selectContratoDatoCount', method: 'dato.select', params: { filter: apiFilterDato, wrapper: 'count' } })
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idcontrato, TABLA.contrato.idtabla, esTecnico, esComercial, esVigilante))
    return await Vue.$api.batchCall(apiCalls)
  },
}
