<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="contratoPendiente && hasEditPerm(permissions.contrato.id)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ContratoViewData'
import { TABLA } from '@/utils/consts'
import { get } from 'vuex-pathify'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: {
        generales: {
          title: 'Datos generales',
          rows: [
            'contrato_serie_numero',
            { name: 'fcontrato', filter: this.$options.filters.shortDate },
            { name: 'ffirma', filter: this.$options.filters.shortDate },
            'objeto',
            'tcontrato_descripcion',
            'cliente_potencial_nombre',
            'cliente_nombre',
            'sistema_descripcion',
            'representante_nombre',
          ],
        },
        duracion: {
          title: 'Duración',
          rows: [
            'meses_permanencia',
            'meses_renovacion',
            { name: 'fvalidez', filter: this.$options.filters.shortDate },
            'duracion',
            { name: 'prorrogable', filter: this.$options.filters.humanizeBoolean },
          ]
        },
        cnp: {
          title: 'CNP',
          rows: [
            { name: 'fcomunicacion_cnp', filter: this.$options.filters.shortDate },
            'ncontrato_cnp',
            'nservicio_cnp',
            'nconexion_cnp',
          ]
        },
        inicioExtra: {
          title: 'Inicio extraordinario',
          rows: [
            { name: 'dur_fexcepcional', filter: this.$options.filters.shortDate },
            'dur_causa',
            'dur_entidad_comunicada',
          ]
        },
      },
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcontrato
        item.title = this.$online.contrato.title(item)
        item.subtitle = this.$online.contrato.subtitle(item)
        const badge = this.$online.contrato.badge(item)
        item.badge = badge.badge
        item.badgeColor = badge.badgeColor
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
    contratoPendiente () {
      return this.item.dataset?.ffirma === null
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.objeto
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectContrato(
          this, this.routeParams.idcontrato
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idcontrato,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // cuotas
      if (this.hasViewPerm(this.permissions.contratoCuota.id)) {
        const detailCuotas = this.addDetail(
          'cuotas', 'Cuotas', 'Cuotas del contrato', 'cuotas'
        )
        detailCuotas.badge = resp.data.selectContratoCuotaCount.result.dataset[0].count || 0
      }
      // servicios contratados
      if (this.hasViewPerm(this.permissions.servicioContratado.id)) {
        const detailCuotas = this.addDetail(
          'servicioContratado', 'Servicios contratados', 'Servicios contratados del contrato', 'servicioContratado'
        )
        detailCuotas.badge = resp.data.selectServicioContratadoCount.result.dataset[0].count || 0
      }
      // material a instalar
      if (this.hasViewPerm(this.permissions.contratoMaterial.id)) {
        const detailCuotas = this.addDetail(
          'materialInstalar', 'Material a instalar', 'Material a instalar del contrato', 'materialInstalar'
        )
        detailCuotas.badge = resp.data.selectContratoMaterialCount.result.dataset[0].count || 0
      }
      // datos de presupuesto
      if (this.hasViewPerm(this.permissions.contratoDato.id)) {
        const detailDatos = this.addDetail(
          'datos', 'Datos', 'Datos personalizados del contrato', 'datoPersonalizado'
        )
        detailDatos.badge = resp.data.selectContratoDatoCount.result.dataset[0].count || 0
      }
      // ficheros
      if (this.hasViewPerm(this.permissions.contratoAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'cuotas') {
        this.$appRouter.push({
          name: 'contratos__cuotas__contratocuota-list',
          params: {
            idcontrato: this.routeParams.idcontrato,
          },
        })
      } else if (data.detail.name === 'servicioContratado') {
        this.$appRouter.push({
          name: 'contratos__servicioscontratados__serviciocontratado-list',
          params: {
            idcontrato: this.routeParams.idcontrato,
          },
        })
      } else if (data.detail.name === 'materialInstalar') {
        this.$appRouter.push({
          name: 'contratos__materiales__material-list',
          params: {
            idcontrato: this.routeParams.idcontrato,
          },
        })
      } else if (data.detail.name === 'datos') {
        this.$appRouter.push({
          name: 'datos__contratos-dato-list',
          params: {
            id: this.routeParams.idcontrato,
            idtabla: TABLA.contrato.idtabla,
            readonly: !this.contratoPendiente,
          },
        })
      } else if (data.detail.name === 'adjuntos') {
        this.$appRouter.push({
          name: 'ficheros__contratos-fichero-list',
          params: {
            id: this.routeParams.idcontrato,
            idtabla: TABLA.contrato.idtabla,
            readonly: !this.contratoPendiente,
          },
        })
      }
    },
    clickEdit () {
      this.$appRouter.push({
        name: 'contratos__contrato-edit',
        params: {
          idcliente: this.routeParams.idcontrato,
        },
      })
    },
  },
}
</script>
